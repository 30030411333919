.evo-aside-container {
    width: 260px;
    background-color: var(--aside-container);
    border-right: 1px solid var(--aside-container-border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.evo-aside-navigation {
    margin: 10px 0;
}

.evo-aside-btn {
    position: relative;
    color: var(--text-color);
    padding: 8px 13px;
    margin: 4px 0;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}

.evo-aside-btn > i {
    font-size: 1.1rem;
    margin-right: 13px;
    padding: 2px;
    vertical-align: middle;
}

.evo-aside-btn.active {
    background-color: var(--aside-btn);
    font-weight: bold;
}

.evo-aside-btn.active::before {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    z-index: 1;
    width: 4px;
    background: var(--aside-btn-active);
}

.evo-aside-btn:hover {
    color: var(--text-color);
    background-color: var(--aside-btn-hover);
}

.evo-aside-divider {
    background-color: inherit;
    height: 20px;
    width: 100%;
}

.evo-aside-donate-container {
    text-align: center;
    padding: 20px;
}

@media screen and (max-width: 500px) {
    .evo-aside-container {
        width: auto;
    }
    
    .evo-aside-btn > i {
        margin-right: 0;
    }

    .evo-aside-btn > span {
        display: none;
    }

    .evo-aside-donate-container  {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}