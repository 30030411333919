* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --text-color: #fff;
    --btn-text-color: #fff;
    --body-bg-color: #212121;
    --welcome-desc: #989898;
    --btn-badge: rgba(255, 255, 255, 0.08);
    --btn-primary: #005BA1;
    --btn-primary-hober: #0067B5;
    --btn-primary-active: #0078D4;
    --btn-secondary: rgba(255, 255, 255, 0.08);
    --btn-secondary-hover: rgba(255, 255, 255, 0.08);
    --btn-danger: rgba(184, 14, 28, 1);
    --btn-danger-hover: rgba(203, 15, 31, 1);
    --overlay-backdrop: rgba(0, 0, 0, 0.35);
    --form-presentation: #333;
    --modal-presentation-title-border: #605e5c;
    --presentation-title-btn: rgba(255, 255, 255, 0.04);
    --element-focus: #4EA1E6;
    --element-shadow: rgba(90, 185, 255, 0.7);
    --form-input: #424141;
    --form-input-border: #8f8f8f;
    --form-input-placeholder: #969696;
    --form-input-search: #434343;
    --imagenes-upload: rgba(255, 255, 255, .08);
    --tr-shadow: rgba(255, 255, 255, 0.1);
    --td-color: rgba(255, 255, 255, 0.59);
    --td-hover: rgba(255, 255, 255, 0.04);
    --imagenes-thumbnails: #777;
    --code-box-body: #1a1a1a;
    --aside-container: #2d2d2d;
    --aside-container-border: #333;
    --aside-btn: #393939;
    --aside-btn-active: #006fc8;
    --aside-btn-hover: #353535;
    --navbar-header: #1a1a1a;
    --container-border: #333;
    --btn-account-color: rgba(255, 255, 255, 0.9);
    --btn-account: rgba(255, 255, 255, 0.04);
    --btn-account-div: #DEFFB3;
    --btn-account-div-color: #4E7C13;;
    --user-info-border: rgba(255, 255, 255, 0.15);
    --user-info-icon: #DEFFB3;
    --user-info-icon-span: #4E7C13;
    --user-info-text-secondary-color: #969696;
    --menu-item-outline: #4f91c2;
    --form-container-text-color: rgba(0, 0, 0, 0.59);
    --login-form: #fff;
    --login-form-btn: #fcfcfc;
    --login-form-hober: #dedede;
    --login-form-border: #bfbfbf;
    --login-form-color: #000;
}

.evo-light-mode {
    --text-color: #000;
    --btn-text-color: #fff;
    --body-bg-color: #dedede;
    --welcome-desc: #676767;
    --form-presentation: #cccccc;
    --form-input: #bdbebe;
    --form-input-border: #707070;
    --form-input-placeholder: #696969;
    --form-input-search: #bcbcbc;
    --imagenes-thumbnails: #888888;
    --aside-container-border: #cccccc;
    --code-box-body: #e5e5e5;
    --navbar-header: #e5e5e5;
    --container-border: #cccccc;
    --aside-container: #d2d2d2;
    --aside-btn: #c6c6c6;
    --aside-btn-hover: #cacaca;
    --user-info-text-secondary-color: #696969;
    --login-form: #1a1a1a;
    --login-form-btn: #1a1a1a;
    --login-form-hober: #212121;
    --login-form-border: #404040;
    --login-form-color: #fff;
    --td-color: rgba(0, 0, 0, 0.59);
    --btn-badge: rgba(0, 0, 0, 0.08);
    --btn-secondary: rgba(0, 0, 0, 0.08);
    --btn-secondary-hover: rgba(0, 0, 0, 0.08);
    --overlay-backdrop: rgba(255, 255, 255, 0.35);
    --presentation-title-btn: rgba(0, 0, 0, 0.04);
    --modal-presentation-title-border: #9fa1a3;
    --imagenes-upload: rgba(0, 0, 0, .08);
    --tr-shadow: rgba(0, 0, 0, 0.1);
    --td-hover: rgba(0, 0, 0, 0.04);
    --btn-account-color: rgba(0, 0, 0, 0.9);
    --btn-account: rgba(0, 0, 0, 0.04);
    --user-info-border: rgba(0, 0, 0, 0.15);
    --form-container-text-color: rgba(255, 255, 255, 0.59);
    --btn-account-div: #C4B7E1;;
    --user-info-icon: #C4B7E1;;
    --btn-account-div-color: #4D23CD;;;
    --user-info-icon-span: #4D23CD;;
    --btn-primary: #005BA1;
    --btn-primary-hober: #0067B5;
    --btn-primary-active: #0078D4;
    --btn-danger: rgba(184, 14, 28, 1);
    --btn-danger-hover: rgba(203, 15, 31, 1);
    --element-focus: #4EA1E6;
    --element-shadow: rgba(90, 185, 255, 0.7);
    --aside-btn-active: #006fc8;
    --menu-item-outline: #4f91c2;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg-color);
}

.evo-row-flex {
    display: flex;
    min-height: 100vh;
}

.evo-home-welcome-title {
    font-size: 21px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 24px;
}

.evo-home-welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 70%;
    flex-grow: 1;
    text-align: center;
}

.evo-home-welcome > h2 {
    font-size: 28px;
    line-height: 40px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.evo-home-welcome-desc {
    color: var(--welcome-desc);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
}

.evo-home-welcome-img {
    width: 500px;
}

.fa-tags {
    font-size: .78rem !important;
}

.fa-assistive-listening-systems {
    font-size: 1rem;
}

.evo-btn-badge {
    background-color: var(--btn-badge);
    color: var(--text-color);
    cursor: pointer;
    border: none;
    border-radius: 2px;
    font-size: .7rem;
    font-weight: 600;
    padding: 4px 8px;
    margin-left: 6px;
    vertical-align: top;
    transition: background-color 80ms linear;
}

.evo-btn {
    color: var(--btn-text-color);
    margin-left: 6px;
    margin-right: 6px;
    padding: 6px 12px;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    transition: background-color 80ms linear;
}

a.evo-btn {
    padding: 7px 12px 8.5px;
    font-weight: 700;
}

.evo-btn-primary {
    background-color: var(--btn-primary);
}

.evo-btn-primary:hover {
    background-color: var(--btn-primary-hober);
}

.evo-btn-primary:active {
    background-color: var(--btn-primary-active);
}

.evo-btn-secondary {
    background-color: var(--btn-secondary);
    color: var(--text-color)
}

.evo-btn-danger {
    background-color: var(--btn-danger);
}

.evo-btn-secondary:hover,
.evo-btn-secondary:active {
    background-color: var(--btn-secondary-hover);
}

.evo-btn-danger:hover,
.evo-btn-danger:active {
    background-color: var(--btn-danger-hover);
}

.evo-overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background-color: var(--overlay-backdrop);
    z-index: 4;
    opacity: 0;
    display: none;
    justify-content: center;
    align-items: center;
}

.evo-overlay-backdrop.show {
    opacity: 1;
    display: flex;
}

.evo-form-presentation {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 600px;
    max-width: 100%;
    z-index: 2;
    margin: 18px;
    color: var(--text-color);
    background-color: var(--form-presentation);
    border-radius: 6px;
    box-shadow: 0 12px 32px rgb(0 0 0 / 12%);
}

.evo-modal-presentation {
    display: flex;
    flex-direction: column;
    width: 98%;
    max-width: 600px;
    z-index: 3;
    color: var(--text-color);
    background-color: var(--form-presentation);
    border-radius: 6px;
    box-shadow: 0 12px 32px rgb(0 0 0 / 12%);
}

.evo-form-presentation-title,
.evo-modal-presentation-title {
    display: flex;
    padding: 24px 32px;
}

.evo-modal-presentation-title {
    border-bottom: 1px solid var(--modal-presentation-title-border);
}

.evo-form-presentation-title > h2,
.evo-modal-presentation-title > h2 {
    flex: 1 1 auto;
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 0;
}

.evo-form-presentation-title > button,
.evo-modal-presentation-title > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    min-width: 0;
    border-radius: 100%;
    font-size: 1.2rem;
    color: var(--text-color);
    background-color: transparent;
    cursor: pointer;
    border: none;
    transition: background-color 80ms linear;
}

.evo-form-presentation-title > button:hover,
.evo-modal-presentation-title > button:hover {
    background-color: var(--presentation-title-btn);
}

.evo-form-presentation-body,
.evo-modal-presentation-body {
    padding: 0 32px 24px;
    flex-grow: 1;
    overflow: auto;
}

.evo-modal-presentation-body {
    padding: 32px 24px;
}

.evo-modal-imagenes-container > .evo-imagenes-upload-file {
    cursor: pointer;
}

.evo-modal-imagenes-container > .evo-imagenes-upload-file.selected {
    box-shadow: 0 0 0 3px var(--element-focus);
}

.evo-form-row {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 -10px;
}

.evo-form-group {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    width: 100%;
}

.evo-form-group > label {
    display: block;
    color: var(--text-color);
    margin-bottom: 6px;
    font-weight: 500;
}

.evo-form-group > p {
    font-size: .8rem;
}

.evo-form-group > input:focus {
    box-shadow: 0 0 0 3px var(--element-focus);
    
}

.evo-form-input-text {
    width: 100%;
    font-size: .9rem;
    font-weight: 600;
    padding: 12px 15px;
    background-color: var(--form-input);
    border-radius: 2px;
    border: 1px solid var(--form-input-border);
    outline: none;
    -webkit-appearance: none;
    color: var(--text-color);
}

.evo-form-input-text::placeholder {
    color: var(--form-input-placeholder);
}

.evo-form-presentation-footer,
.evo-modal-presentation-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 32px;
    border-top: 1px solid var(--modal-presentation-title-border);
}

.evo-imagenes-container,
.evo-modal-imagenes-container {
    display: flex;
    flex-wrap: wrap;
}

.evo-imagenes-upload {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    margin-right: 14px;
    margin-bottom: 14px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    border-radius: 25%;
    background-color: var(--imagenes-upload);
    cursor: pointer;
}

.evo-imagenes-upload > i {
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 50ms linear;
}

.evo-imagenes-upload:hover > i {
    opacity: 1;
}

.evo-imagenes-upload-file {
    width: 75px;
    height: 75px;
    margin-right: 14px;
    margin-bottom: 14px;
    border-radius: 25%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--imagenes-upload);
    position: relative;
}

.evo-imagenes-upload-file > button {
    position: absolute;
    right: 7px;
    top: 7px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: var(--btn-text-color);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th {
    font-size: .9rem;
    text-align: left;
    padding: 10px 0;
    width: calc(100% / 3);
}

table > tbody > tr > td {
    font-size: .8rem;
    text-align: left;
}

#sp_countProducts {
    padding: 0.2rem 0rem 0.2rem 0.5rem !important;
}

.evo-row-textbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 1px;
}

.evo-textbox-style {
    width: 50%;
    padding: 0.2rem 0rem 0.2rem 0.5rem !important;
    line-height: 1.5rem !important;
    min-height: 1.5rem !important;
    font-size: .8rem;
    font-weight: 600;
    background-color: var(--form-input);
    border-radius: 2px;
    border: 1px solid var(--form-input-border);
    outline: none;
    -webkit-appearance: none;
    color: var(--text-color);
}

.evo-textbox-style::placeholder {
    color: var(--form-input-placeholder);
}

.evo-textbox-style:focus {
    box-shadow: 0 0 0 3px var(--element-focus);
}

.evo-textbox-style[type="number"] {
    width: 75px;
}

.evo-textbox-style + .evo-fontawesome-icon {
    margin: 0 10px;
    font-size: 1rem;
    cursor: pointer;
}

.evo-row-flex-inTable {
    display: inline-flex;
}

.evo-row-flex-inTable > .evo-imagenes-upload {
    width: 25px !important;
    height: 25px !important;
    margin: 0;
    border-width: 2px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--imagenes-upload);
}

.evo-row-flex-inTable > .evo-imagenes-upload > i {
    font-size: .8rem;
}

.evo-row-flex-inTable > .evo-imagenes-upload[style^="background-image"] > i {
    opacity: 0;
}

.evo-form-group-hr {
    border-top: 1px solid var(--modal-presentation-title-border);
    padding: 0;
    padding-top: 1rem;
    margin: 0 10px;
}

.evo-table-container {
    background-color: var(--form-presentation);
    box-shadow: 0 3px 6px rgb(0 0 0 / 4%);
    border-radius: 4px;
}

.evo-table-header {
    display: flex;
    align-items: center;
    padding: 16px 0;
}

.evo-table-header-item {
    position: relative;
    padding: 4px;
}

.evo-table-header-item > button {
    margin: 0px;
}

.evo-table-header-item + .evo-table-header-item{
    margin-left: 20px;
}

.evo-table-header-item-last {
    margin-left: auto !important;
    white-space: nowrap;
}

.evo-table-header-item-last > a.evo-btn {
    padding: 7px 12px 7.5px;
    margin-right: 0;
}

.evo-table-header-item > h2 {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 17px;
}

.evo-table-header-item-search > i {
    color: var(--form-input-placeholder);
    position: absolute;
    top: 12px;
    left: 14px;
}

.evo-input-search {
    padding: 5px 11px;
    border-width: 1px;
    padding-left: 34px;
    background-color: var(--form-input-search);
    border: 1px solid;
    border-color: transparent;
    border-radius: 2px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    outline: none;
}

.evo-input-search::placeholder {
    color: var(--form-input-placeholder);
}

.evo-input-search:focus {
    border-color: var(--element-focus);
    box-shadow: 0 0 0 3px var(--element-shadow);
}

.evo-table-body thead > tr {
    box-shadow: inset 0 -1px 0 0 var(--tr-shadow);
}

.evo-table-body th {
    padding: 16px 22px;
    color: var(--td-color);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    width: auto;
}

.evo-table-body td {
    padding: 20px 22px;
    color: var(--td-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.evo-table-body tbody > tr:hover > td {
    background-color: var(--td-hover);
}

.evo-imagenes-thumbnails {
    border: 2px dashed var(--imagenes-thumbnails);
    border-radius: 7px;
    width: 150px;
    height: 100px;
    flex-direction: column;
}

.evo-imagenes-thumbnails-file {
    width: 120px;
    height: 120px;
    background-size: cover;
}

.evo-imagenes-thumb-generated {
    max-width: 536px;
}

.evo-code-box-body {
    margin-top: 1rem;
    font-size: .8rem;
}

.evo-code-box-body > p {
    margin-bottom: .5rem;
}

.evo-code-box-body > code {
    border-radius: 3px;
    padding: 5px;
    display: block;
    background-color: var(--code-box-body);
    overflow: auto;
    max-height: 250px;
    white-space: pre-line;
}

.evo-home-welcome-accions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-content: space-between;
    height: 80px;
}

@media screen and (max-width: 500px) {
    .evo-row-flex {
        flex-direction: column;
    }

    .evo-form-presentation {
        width: auto;
        margin: 18px 18px 120px;
        left: 0;
        min-width: auto;
    }

    .evo-row-flex-inTable {
        margin: 5px 0 10px 0;
        flex-basis: 100%;
    }

    #table_preview .evo-textbox-style[type="number"]{
        width: 65px;
    }

    #table_preview .evo-textbox-style[type="text"]{
        width: 75px;
    }

    #table_preview > tbody > tr > td {
        vertical-align: super;
    }

    .evo-home-welcome-title {
        font-size: 18px;
    }

    .evo-home-welcome-img {
        width: 100%;
    }

    .evo-home-welcome > h2 {
        font-size: 22px;
    }

    .evo-home-welcome-desc {
        font-size: 12px;
    }

    .evo-imagenes-thumbnails-file {
        width: 85px;
        height: 85px;
    }
}