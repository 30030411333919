.evo-layaout-container {
    display: flex;
    height: calc(100vh - 51px);
}

.evo-layout-section {
    flex-grow: 1;
    padding: 24px 32px;
    color: var(--text-color);
    overflow: auto;
}

.evo-layout-section-box {
    border-radius: 4px;
}

@media screen and (max-width: 500px) {
    .evo-layaout-container {
        overflow: hidden;
    }

    .evo-layout-section {
        display: flex;
    }

    table {
        table-layout: fixed;
    }
}