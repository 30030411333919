.evo-login-presentation {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
    padding: 40px;
    color: var(--text-color);
}

.evo-login-title > h2 {
    margin: 20px 20px 10px 20px;
    font-size: 26px;
    line-height: 40px;
}

.evo-login-title > div {
    margin: 0px 20px 10px 20px;
    font-size: 14px;
    line-height: 20px;
}

.evo-login-logo {
    margin-top: 10px;
    min-height: 280px;
    max-height: calc(100vh - 270px);
}

.evo-login-form {
    margin: 20px;
    border-radius: 6px;
    flex: 0 0 550px;
    padding: 40px;
    background-color: var(--login-form);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.evo-login-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.evo-login-form-container > div > h2 {
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    color: var(--login-form-color);
}

.evo-login-form-container > div > p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: var(--form-container-text-color);
}

.evo-login-form-btn-container {
    margin: 24px 0 16px 0;
}

.evo-login-form-btn {
    margin-top: 10px;
    padding: 12px 16px;
    background-color: var(--login-form-btn);
    border: 1px solid var(--login-form-border);
    border-radius: 2px;
    min-width: 100px;
    font-size: 18px;
    font-weight: normal;
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    color: var(--login-form-color);
}

.evo-login-form-btn:hover {
    background-color: var(--login-form-hober);
}

.evo-login-form-btn:first-child {
    margin-top: 0;
}

.evo-login-form-btn > .evo-login-form-btn-icon {
    height: 32px;
    width: 32px;
    background-size: contain;
}

.evo-login-form-btn > span {
    padding-left: 8px;
    font-size: 16px;
}

.evo-login-copy-right {
    display: flex;
    justify-content: flex-end;
}

.evo-login-copy-right > span {
    font-size: 12px;
    line-height: 16px;
    color: var(--form-container-text-color);
}

@media screen and (max-width: 500px) {
    .evo-login-presentation {
        height: 80px;
        padding: 0;
    }

    .evo-login-title {
      display: none;
    }

    .evo-login-logo {
        margin-top: 0;
        min-height: 135px;
    }

    .evo-login-form {
        flex: 1 0 100%;
        margin: 0;
        border-radius: 0;
    }
}