.evo-navbar-header {
    background-color: var(--navbar-header);
    position: sticky;
    top: 0;
}

.evo-navbar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--container-border);
}

.evo-navbar-brand {
    display: inline-block;
    margin-right: auto;
    cursor: pointer;
    text-decoration: none;
}

.evo-navbar-btn {
    color: var(--text-color);
    padding: 12px;
    cursor: pointer;
    background: transparent;
    border: none;
    box-shadow: none;
}

.evo-navbar-btn > i {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 1rem;
}

.evo-navbar-btn-account {
    padding: 12px;
    cursor: pointer;
    background: transparent;
    border: none;
    box-shadow: none;
}

.evo-navbar-btn-account:hover,
.evo-navbar-btn:hover {
    color: var(--btn-account-color);
    background-color: var(--btn-account);
}

.evo-navbar-btn-account > div {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background-color: var(--btn-account-div);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    color: var(--btn-account-div-color);
}

.evo-navbar-brand-logo {
    width: 70px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.evo-user-menu {
    position: absolute;
    min-width: 140px;
    width: 243px;
    inset: 51px 0px auto auto;
    padding-top: 8px;
    margin-bottom: 10px;
    background-color: var(--container-border);
    box-shadow: 0 6px 12px rgb(0 0 0 / 8%);
    border-radius: 4px;
    z-index: 3;
}

.evo-user-info {
    padding: 0 8px 8px;
    margin: 0 8px 8px 8px;
    border-bottom: 1px solid var(--user-info-border);
    color: var(--text-color);
}

.evo-user-info-box {
    padding: 4px;
    padding-left: 8px;
    position: relative;
    left: -8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.evo-user-info-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 100%;
    background-color: var(--user-info-icon);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.evo-user-info-icon > span {
    color: var(--user-info-icon-span);
    font-size: 12px;
}

.evo-user-info-desc {
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: -3px;
    margin-bottom: -3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.evo-user-info-desc > span {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.evo-user-info-text-primary {
    color: var(--text-color);
    font-weight: 600;
}

.evo-user-info-text-secondary {
    color: var(--user-info-text-secondary-color);
    font-weight: 600;
}

.evo-list-item {
    padding-bottom: 8px;
}

.evo-list-item-box {
    position: relative;
    padding-bottom: 0;
}

.evo-menu-item {
    display: flex;
    align-items: center;
    color: var(--text-color);
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
}

.evo-menu-item:hover {
    outline: 3px solid var(--menu-item-outline);
    outline-offset: -3px;
}

.evo-menu-item > i {
    margin: 2px;
    margin-right: 8px;
    font-size: 1.1rem;
}